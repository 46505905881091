<template>

  <div class="pdf-container">
    <div class="pdf-document">
      <div class="pdf-preview">
        <loader v-if="
            !previewContract || !previewLoonheffing || (!ignoreCodeOfConduct && !previewCodeOfConduct)
        " />
        <div v-else class="content">

          <pdf 
              v-for="i in pageCountContract"
              :key="`contract-${i}`"
              :src="previewContract"
              :id="`contract-${i}`"
              :page="i"
              style="width:100%; margin:20px auto;"
              :annotation="false"
              :resize="true"
              :text="false"
              scale.sync="scale"
          />
          
          <template v-if="!zzp">
              <pdf
                  v-for="i in pageCountLoonheffing"
                  :key="`lhk-${i}`"
                  :src="previewLoonheffing"
                  :id="`lhk-${i}`"
                  :page="i"
                  style="width:100%; margin:20px auto;"
                  :annotation="false"
                  :resize="true"
                  :text="false"
                  scale.sync="scale"
              />
          </template>

          <template v-if="previewCodeOfConduct">
              <pdf 
                  v-for="i in pageCountCodeOfConduct"
                  :key="`coc-${i}`"
                  :src="previewCodeOfConduct"
                  :id="`coc-${i}`"
                  :page="i"
                  style="width:100%; margin:20px auto;"
                  :annotation="false"
                  :resize="true"
                  :text="false"
                  scale.sync="scale"
              />
          </template>
          
        </div>
      </div>
      <div class="pdf-sign" @click="checkSignature">
        <template v-if="signed">
          <div class="columns">
            <p class="column is-full has-text-success has-text-centered">
              {{ $t('message.signing.signed') }}
            </p>
            <!-- TODO: Improve message -->
          </div>
        </template>
        <template v-else>
          <div class="columns">
            <div class="column is-6">
              <span class="font-bold">{{ $t('message.signing.signature') }}</span>
              <span class="float-right">
                <span class="clickable-link" @click="undoSignature"
                  >{{ $t('message.signing.undo') }}</span
                >
                <span>&nbsp;|&nbsp;</span>
                <span class="clickable-link" @click="clearSignature"
                  >{{ $t('message.signing.clear') }}</span
                >
              </span>
              <template>
                <VueSignaturePad
                  ref="signaturePad"
                  class="sign-box"
                  width="100%"
                  height="7.5rem"
                  :options="{
                    onBegin: () => {
                      $refs.signaturePad.resizeCanvas();
                    },
                  }"
                />
              </template>
            </div>
            <div class="column">
              <div class="columns">
                <div class="column">
                  <label class="confirmread"
                    ><input
                      v-model="confirmRead"
                      type="checkbox"
                      name="confirmread"
                    />&nbsp;
                    <i v-if="zzp">{{ $t('message.signing.declare_zzp') }}</i>
                    <i v-else>{{ $t('message.signing.declare') }}</i>
                    <div class="status" :class="{ active: confirmRead }"></div>
                  </label>
                </div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column is-narrow" style="display: flex;">
                  <div
                    v-if="saveButtonEnabled"
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                    style="align-self: flex-end;"
                    @click="saveSignature"
                  >
                    {{ $t('message.signing.sign') }}
                  </div>
                  <div
                    v-else
                    class="button is-primary"
                    disabled="disabled"
                    style="align-self: flex-end;"
                  >
                    {{ $t('message.signing.sign') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueSignaturePad from "vue-signature-pad";
import moment from "moment";
import pdf from 'pdfvuer'
import Api from "../api/api";
import Loader from '../components/Loader.vue';


let api = new Api();

export default {
  components: {
    VueSignaturePad,
    pdf,
    Loader,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: "nl",
    },
    checksum: {
      type: String,
    },
    setSigned: {
      type: Function
    },
    followupContract: Boolean,
    zzp: Boolean
  },
  data() {
    return {
      
      today: moment().format("DD-MM-YYYY"),
      complete: false,
      loading: false,
      loadingContract: false,
      loadingLoonheffing: false,
      loadingCodeOfConduct: false,
      pageCountContract: 0,
      pageCountLoonheffing: 0,
      pageCountCodeOfConduct: 0,
      signed: false,
      previewContract: null,
      previewLoonheffing: null,
      previewCodeOfConduct: null,
      ignoreCodeOfConduct: false,
      confirmRead: false,
      isSignatureFilled: false,
      user: null
    };
  },

  computed: {
    saveButtonEnabled() {
      if (
        (this.confirmRead) &&
        this.isSignatureFilled &&
        this.previewContract &&
        this.previewLoonheffing &&
        (this.ignoreCodeOfConduct || this.previewCodeOfConduct)
      ) {
        return true;
      }

      return false;
    },
  },

  async mounted() {
    this.makePdf(); 
  },

  methods: {
    makePdf: function() {
      this.loadingContract = true;

      if (this.followupContract) {
        api
          .get(`/contract/preview/${this.language}`)
          .then((response) => {
            this.previewContract = pdf.createLoadingTask({data: atob(response.data)});
            this.previewContract.then(pdf => {
              this.pageCountContract = pdf.numPages;
            });
            this.loadingContract = false;
          })
          .catch(() => {    
            this.loadingContract = false;
          });

        api
          .get(`/contract/taxdeduction/preview/${this.language}`)
          .then((response) => {
            this.previewLoonheffing = pdf.createLoadingTask({data: atob(response.data)});
            this.previewLoonheffing.then(pdf => {
              this.pageCountLoonheffing = pdf.numPages;
            });
            this.loadingLoonheffing = false;
          })
          .catch(() => {    
            this.loadingLoonheffing = false;
          });

        api
          .get(`/contract/codeofconduct/preview/${this.language}`)
          .then((response) => {
            if (response.status === "success") {
              this.ignoreCodeOfConduct = true;
            }
            this.previewCodeOfConduct = pdf.createLoadingTask({data: atob(response.data)});
            this.previewCodeOfConduct.then(pdf => {
              this.pageCountCodeOfConduct = pdf.numPages;
            });
            this.loadingCodeOfConduct = false;
          })
          .catch(() => {    
            this.loadingCodeOfConduct = false;
          });

        return;
      }

      this.loadingLoonheffing = true;
      this.loadingCodeOfConduct = true;

      api
        .get(`/contract/${this.hash}/${this.checksum}/preview/${this.language}`)
        .then((response) => {
          this.previewContract = pdf.createLoadingTask({data: atob(response.data)});
          this.previewContract.then(pdf => {
            this.pageCountContract = pdf.numPages;
          });
          this.loadingContract = false;
        })
        .catch(() => {
          this.loadingContract = false;
        });

      api
        .get(
          `/taxdeduction/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          this.previewLoonheffing = pdf.createLoadingTask({data: atob(response.data)});
          this.previewLoonheffing.then(pdf => {
            this.pageCountLoonheffing = pdf.numPages;
          });
          this.loadingLoonheffing = false;
        })
        .catch(() => {
          this.loadingLoonheffing = false;
        });

      api
        .get(
          `/codeofconduct/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          if (response.status === "success") {
            this.ignoreCodeOfConduct = true;
          }
          this.previewCodeOfConduct = pdf.createLoadingTask({data: atob(response.data)});
          this.previewCodeOfConduct.then(pdf => {
            this.pageCountCodeOfConduct = pdf.numPages;
          });
          this.loadingCodeOfConduct = false;
        })
        .catch(() => {
          this.loadingCodeOfConduct = false;
        });
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },

    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },

    saveSignature() {
      if (
        this.loading ||
        !this.previewContract ||
        !this.previewLoonheffing || 
        (
          !this.ignoreCodeOfConduct &&
          !this.previewCodeOfConduct
        )
      ) {
        return;
      }

      this.loading = true

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        this.loading = false;
        return;
      }

      const fields = {
        employee_signature: data,
      };

      if(this.followupContract) {
        api
          .patch(`/contract/sign/${this.language}`, {
              signature_data: data
          })
          .then(() => {
            this.loading = false;
            this.$refs.signaturePad.lockSignaturePad();
            this.complete = true;
            this.setSigned()
          })
          .catch((error) => {
            this.loading = false;
            console.log("Error");
            console.log(error);
          });

          return;
      }
 
      api
        .post(`/contract/${this.hash}/${this.checksum}/sign/${this.language}`, fields)
        .then(() => {
          this.loading = false;
          this.$refs.signaturePad.lockSignaturePad();
          this.complete = true;
          this.setSigned()
        })
        .catch((error) => {
          this.loading = false;
          console.log("Error");
          console.log(error);
        });
    },

    checkSignature() {
      const signatureData = this.$refs.signaturePad.toData();
      let points = 0;

      // Count total points
      signatureData.forEach((pointArray) => {
        points = points + pointArray.points.length;
      });

      if (points > 5) {
        this.isSignatureFilled = true;
      } else {
        this.isSignatureFilled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container {
  position: fixed;
  padding: 2rem 2rem 0 2rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #efefef;
  z-index: 2000;
  background: transparent;

  .pdf-document {
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 58px -27px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 58px -27px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 58px -27px rgba(0, 0, 0, 0.75);

    .pdf-preview {
      flex: 1;
      background-color: #eee;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow-x: auto;
      padding: 1rem;

      .content {
        height: 1500px;
      }
    }
    .pdf-sign {
      background-color: #f5f5f5;
      border-top: 1px solid #d8d8d8;
      padding: 2rem;

      .sign-box {
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border-radius: 3px;
        background-color: #fff;
        border: 1px solid transparent;
        border-color: #dbdbdb;
        cursor: crosshair;
      }

      .button-col {
        display: flex;
        align-items: flex-end;
      }

      .font-bold {
        color: #616161;
        font-weight: 500;
        line-height: 2rem;
      }
      .float-right {
        font-size: 0.8rem;
        float: right;
        line-height: 2.2rem;
        color: #888888;

        .clickable-link {
          cursor: pointer;
          text-decoration: underline;

          &:hover {
            color: #616161;
          }
        }
      }
    }
  }
}
</style>
