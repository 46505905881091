<template>
  <div>
    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.invoice_name") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="business.name"
              class="input"
              :class="{ 'is-danger': required('name') }"
              type="text"
              :placeholder="`${$t('message.fields.zzp.invoice_name')}...`"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.invoice_email") }}
            <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="business.email"
              class="input"
              :class="{ 'is-danger': required('email') }"
              type="text"
              :placeholder="`${$t('message.fields.zzp.invoice_email')}...`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.iban") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="business.iban"
              class="input"
              :class="{ 'is-danger': required('iban') }"
              type="text"
              :placeholder="`${$t('message.fields.zzp.iban')}...`"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.coc") }}
            <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="business.coc"
              class="input"
              :class="{ 'is-danger': required('coc') }"
              type="text"
              :placeholder="`${$t('message.fields.zzp.coc')}...`"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.btw_id") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="business.btw_id"
              class="input"
              :class="{ 'is-danger': required('btw_id') }"
              type="text"
              :placeholder="`${$t('message.fields.zzp.btw_id')}...`"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.invoice_due_days") }}
            <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="business.invoice_due_days"
              class="input"
              :class="{ 'is-danger': required('invoice_due_days') }"
              type="number"
              min="1"
              :placeholder="`${$t('message.fields.zzp.invoice_due_days')}...`"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.financial.bsn") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="business.bsn"
              class="input"
              :class="{ 'is-danger': required('bsn') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.bsn')}...`"
              tabindex="18"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.kor") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="business.kor"
                :class="{ 'is-danger': required('kor') }"
                type="radio"
                :value="1"
              />
              {{ $t("message.options.yes") }}
            </label>
            <label class="radio">
              <input
                v-model="business.kor"
                :class="{ 'is-danger': required('kor') }"
                type="radio"
                :value="0"
              />
              {{ $t("message.options.no") }}
            </label>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label"
            >{{ $t("message.fields.zzp.btw_percentage") }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="business.btw_percentage"
                :class="{ 'is-danger': required('btw_percentage') }"
                type="radio"
                :value="1"
              />
              0 %
            </label>
            <label class="radio">
              <input
                v-model="business.btw_percentage"
                :class="{ 'is-danger': required('btw_percentage') }"
                type="radio"
                :value="3"
              />
              21 %
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["business"],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined, // Browser locale
    };
  },
  methods: {
    required: function () {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mr_responsive {
  margin-right: 5%;
}
</style>
