<template>
  <div>
    <!-- NAVBAR -->
    <nav class="navbar">
      <section class="section">
        <h1 class="mr-3">{{ $t('message.work_at', { appClient: $appClient }) }}</h1>
      </section>
      <div class="language-selector" v-if="languageSelector">
        <a 
          v-for="locale in locales" 
          :key="locale.language"
          href="#"
          @click.prevent="$emit('switch-locale', locale)"
        >
          <i
            class="flag-icon flag-icon-squared" 
            :class="`flag-icon-${locale.flag}`"
          />
        </a>
      </div>
      <div class="layer" />
    </nav>

    <!-- CONTENT -->
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    languageSelector: Boolean
  },
  data() {
    return {
      locales: [
        {
          i18n: 'nl',
          flag: 'nl',
          language: 'nl'
        }, 
        {
          i18n: 'en',
          flag: 'gb',
          language: 'en'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  background: #f5f5f5;
  top: 0;
  justify-content: center;

  .section {
    margin-top: 35px;

    h1 {
      text-align: center;
    }
  }
}

.navbar {
  min-height: 35vh;
  background-image: url("../assets/images/bg.png");
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  background-size: cover;
  .section {
    // padding-top: 7%;
    position: absolute;
    z-index: 100;
    h1 {
      display: inline;
      font-size: 2rem;
      color: white;
      span {
        font-weight: 700;
      }
    }
  }
  .layer {
    background-color: rgba(#2495df, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.language-selector {
  position: absolute;
  right: 0;
  z-index: 200;
  padding: 20px;

  a {
    padding: 5px;
  }

  .flag-icon {
    width: 35px;
    height: 35px;
    border-radius: 20px;
  }
}
</style>