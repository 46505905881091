<template>
    <div
          class="loading-container"
          style="height: 100%; width: 100%; float: left; display:flex; justify-content: center; align-items: center;"
        >
          <div
            class="loader-cont"
            style="background-color: transparent !important;"
          >
            <img
              style="height: 120px !important; margin: auto; display: block;"
              :src="'loading-image.svg'"
              alt=""
            />
          </div>
        </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>